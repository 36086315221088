import React, { useEffect } from 'react';
import builderPathInit from '../../util/builder-path-init';
import FriendbuyAuthScript from '../../util/friendbuy-scripts';
import { fetchState, useUser } from '../../context/UserContext';

const { BuilderPage, getServerSideProps } = builderPathInit({
  model: 'builder-page',
  pathPrefix: 'b',
});

/* We only need full Friendbuy auth in a few places, so 
 * instead of slowing down all pages, we'll contain it.
*/
function MarketingPage(props) {
  const { user, userFetchState } = useUser();

  useEffect(() => {
    if(userFetchState === fetchState.COMPLETE && Object.keys(user).length < 1) {
      window.friendbuyAPI = window.friendbuyAPI || [];
      const {friendbuyAPI} = window;

      (friendbuyAPI || []).push(["logout"]);
      window.analytics.reset();
    }
  }, [user, userFetchState]);
  return (
    <>
      <FriendbuyAuthScript />
      <BuilderPage {...props} />
    </>
  );
};

export default MarketingPage;
export { getServerSideProps };